import React, { useState, useEffect, useRef } from "react";
import "./chat.scss";
import chatbot_icon from "../images/chatbot.svg";
import ChatHeader from "./ChatHeader";
import ChatMessage from "./ChatMassage";
import TypingIndicator from "./TypingIndicator";
import ChatInput from "./ChatInput";
import { getChatBotResponse } from "../services/chatResponse";
import { error_responses } from "../constants/error-responses";
import Footer from '../components/footer/index'

function Chatbot(props) {

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const bottomRef = useRef(null);
  const [typeStatus, setTypeStatus] = useState(null)
  const [IsTypeCompleted, setIsTypeCompleted] = useState(false);
  const [scrollState, setScrollState] = useState('')
  const [hasChatHistory, setHasChatHistory] = useState(false)
  const [isInitiativeBuble, setIsInitiativeBuble] = useState(true)
  const [focus, setFocus] = useState(true)
  const scrollContainerRef = useRef(null);
  const scrollPosition = useRef(-1)
  const isBottom = useRef(true);
  const [isShowBottomButton, setIsShowBottomButton] = useState(true)

  const handleToggleChat = () => {

    if (!Array.isArray(chatHistory) || chatHistory.length > 0) {
      setHasChatHistory(true)

    }
    setIsOpen(() => {
      if (!isOpen && messages.length === 0) {
        setIsLoading(true);
        setTimeout(() => {
          setMessages([
            ...messages,
            { text: "Hi, I’m IdeaBot! I have been custom-built using the latest AI intelligence to understand data from a variety of sources to generate intelligent and accurate responses. Ask me anything about IdeaBits or select an option below to get started.", isUser: false, isHistoryItem: true },
          ]);
          setIsLoading(false);
        }, 1000);
      }
      return !isOpen;
    });

    // set historyItem true when chat has history
    messages.map((item) => {
      item.historyItem = true
      return item;
    })
  };

  useEffect(() => {
    const metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    metaViewport.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    document.head.appendChild(metaViewport);

    return () => {
      document.head.removeChild(metaViewport);
    };
  }, []);

  const handleSendMessageClick = async (question) => {
    isBottom.current = true
    setIsInitiativeBuble(false)
    setScrollState(true)
    setHasChatHistory(false)
    setIsTypeCompleted(false)
    setMessages((prev) => [...prev, { text: question, isUser: true, }]);

    if (question.length > 200) {
      setMessages((prev) => [
        ...prev,
        { text: error_responses.INVALID_CHARACTER_LENGTH, isUser: false, },
      ]);
    } else {

      try {
        setIsLoading(true);

        const questionData = {
          question: question,
          chat_history: chatHistory
        };

        if (messages.length > 10) {
          const firstTwoQA = chatHistory.slice(1, 3);
          const lastSixQA = chatHistory.slice(chatHistory.length - 4, chatHistory.length);

          questionData.chat_history = firstTwoQA.concat(lastSixQA);
        }

        const response = await getChatBotResponse(props.apiKey, questionData, sessionId);
        setIsLoading(false);
        setMessages((prev) => [
          ...prev,
          { text: response.data.text, isUser: false },
        ]);


        setChatHistory(prevArray => [...prevArray, { question: question, answer: response.data.text }]);

        if (response.data.session_id) {
          setSessionId(response.data.session_id);
        }
      } catch (error) {
        setMessages((prev) => [
          ...prev,
          { text: error_responses.INTERNAL_SEVER_ERROR, isUser: false, isHistoryItem: true },
        ]);
        setIsLoading(false);
      }
    }
    setScrollState(false)
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollEvent = e.target
      const screenWidth = window.innerWidth
      let position
      if(screenWidth < 1024){
         position = Math.round(scrollEvent.scrollTop)
      }else{
         position = Math.floor(scrollEvent.scrollTop)
      }
      const isBottomPosition = scrollEvent.scrollHeight - (position) === scrollEvent.clientHeight;
      isBottom.current = isBottomPosition
      setIsShowBottomButton(isBottomPosition)

    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [typeStatus]);


  useEffect(() => {

    if (isOpen) {
      bottomRef?.current?.scrollIntoView();
    }

  }, [isOpen])


  useEffect(() => {
    if (scrollPosition.current !== 0 && isBottom.current) {
      bottomRef?.current?.scrollIntoView();
    }
  }, [typeStatus])




  const updateValue = (status) => {
    if (status) {
      setIsTypeCompleted(status)
    }

  }

  const renderCount = (count) => {
    setTypeStatus(count)
  }

  const bubbleOneClick = (text) => {
    isBottom.current = true
    if (!isLoading && IsTypeCompleted) {
      handleSendMessageClick(text)
      setIsInitiativeBuble(false)

      if (focus) {
        setFocus(false)
      }

    }
  }


  const scrollToBottom = () => {
    isBottom.current = true
    bottomRef.current.scrollIntoView({ block: "center", behavior: 'smooth' })
  }

  const renderChatComponent = () => {

    const lastObjectIndex = messages.length - 1;
    const firstObjectIndex = messages[0];
    const lastObject = messages[lastObjectIndex];

    if (firstObjectIndex) {
      firstObjectIndex.isHistoryItem = true;
    }
    const restOfItems = messages.slice(1, messages.length - 1);

    for (let i = 0; i < restOfItems.length; i++) {
      restOfItems[i].isHistoryItem = true;
    }
    if (lastObject) {
      lastObject.isHistoryItem = false;
    }
    return messages.map((message, index) => (
      <ChatMessage
        index={index}
        isUser={message.isUser}
        text={message.text}
        newMessage={message}
        finishStatus={updateValue}
        renderCount={renderCount}
        isFirstMessage={isInitiativeBuble}
        bubbleOneClick={bubbleOneClick}
        focus={focus}
        ref={scrollContainerRef}
      />
    ))
  }
  return (
    <div>
      {isOpen && (
        <div className={`ideabot-chat-widget ${isOpen ? "open" : ""}`}>
          <ChatHeader heading={"IdeaBot"} handleToggle={handleToggleChat} />
          <div className="ideabot-chat-messages" ref={scrollContainerRef}>
            {renderChatComponent()}

            {isLoading && <TypingIndicator />}
            <div ref={bottomRef} />


          </div>
          {!isShowBottomButton ? <button className="bubble-arrow" onClick={scrollToBottom}><img src={require('../images/down-arrow.png')} alt="down-arrow" /></button> : null}
          <ChatInput handleSendMessageClick={handleSendMessageClick} isDisabled={!IsTypeCompleted} />
          <Footer />
        </div>
      )}
      {!isOpen ? (
        <div className="ideabot-chatBtn" onClick={handleToggleChat}>
          <img
            src={chatbot_icon}
            alt="avatar"
            className="ideabot-chat-message-avatar"
          />
        </div>
      ) : null}
    </div>
  );
}

export default Chatbot;