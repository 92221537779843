import React, { createContext, useState, useEffect } from 'react';

export const FocusContext = createContext();


export const FocusProvider = ({ children }) => {
    const [focus, setFocus] = useState(true);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            setFocus(false)
        }
    };
    return (
        <FocusContext.Provider value={{ focus, setFocus }}>
            {children}
        </FocusContext.Provider>
    );
};

