import { useState, useRef, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import './styles.scss';
import send_btn from "../../images/send.svg";


function ChatInput(props) {

  const [inputValue, setInputValue] = useState("");
  const textareaRef = useRef(null);
  const inputDiv = useRef(null);
  const btnDiv = useRef(null);

  const isInputEmpty = () => {
    if (props.isDisabled || inputValue.length <= 1) {
      return true;
    }
    return inputValue.length === 0;
  };

  const handleSendMessageClick = (e) => {
    e.preventDefault();
    props.handleSendMessageClick(inputValue)
    setInputValue("");
  }

  useEffect(() => {
    if (!props.isDisabled && !isMobile) {
      textareaRef.current.focus();
    }
  }, [props.isDisabled]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue)

  };

  useEffect(() => {
    if (textareaRef.current.scrollHeight > 56) {
      textareaRef.current.style.overflow = 'auto'
    } else {
      textareaRef.current.style.overflow = 'hidden'
    }
  }, [inputValue])

  const enterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSendMessageClick(e);
    }
  }

  return (
    <div className="ideabot-chat-input" ref={inputDiv}>
      <form onSubmit={handleSendMessageClick} className="ideabot-input-container">
        <textarea

          type="text"
          placeholder="Say something to the assistant"
          value={inputValue}
          onChange={handleChange}
          className="user-input textarea-no-zoom "
          disabled={props.isDisabled}
          ref={textareaRef}
          onKeyDown={enterPress}
          rows={4}
        />
        <div className="ideabot-button-wrapper">
          <button disabled={isInputEmpty()} type="submit" ref={btnDiv}>
            <img
              src={send_btn}
              alt="avatar"
              className="ideabot-chat-message-avatar"
            />
          </button>
        </div>
      </form>

    </div>
  );
}

export default ChatInput;