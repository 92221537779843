
import React, { useState, useEffect } from "react";
import './styles.scss'


const InitiativeBuble = ({ text, onClick }) => {
    const [value, setValue] = useState(text)

    useEffect(() => {
        setValue(text)
    }, [text])
    return (<div className="ideabot-initiative-buble">
        <div className="ideabot-button" onClick={() => onClick(value)}>
            <p>{text}</p>
        </div>
    </div>)
}

export default InitiativeBuble;