import axios from "axios";
const api_url = process.env.REACT_APP_CHAT_API;
// const api_url = "http://ideabot-qa.eu-north-1.elasticbeanstalk.com";
// const api_url = "http://localhost:3000";

export const getChatBotResponse = async (apiKey, questionData, sessionId) => {
  const config = {
    headers: {
      "x-api-key": apiKey,
      "session-id": sessionId || null
    }
  }

  const response = await axios
    .post(`${api_url}/chat`, questionData, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error ", error);
      // return error.message;
      throw new Error(error);
    });

  return response;
};
