import "./styles.scss";
import closeBtn from "../../images/close.svg";
import logo from "../../images/logo.svg";

const ChatHeader = (props) => {
  return (
    <div className="ideabot-chat-header" style={props.styles}>
      <div className="header-row">
        <div className="ideabot-avatar-container">
          <img
            src={logo}
            alt="avatar"
            className="ideabot-chat-message-avatar"
          />
        </div>
        <p>{props.heading}</p>
      </div>
      <img src={closeBtn} onClick={props.handleToggle} className="close-chat" />
    </div>
  );
};

export default ChatHeader;
