import './styles.scss'
import avatar_b from "../../images/ideabot-logo.png"

const TypingIndicator = () => {
  return (
    <div className="ideabot-typing-wrapper">
      <div className="ideabot-avatar-wrapper">
        <img
          src={avatar_b}
          alt="avatar"
          className="ideabot-chat-message-avatar"
        />
      </div>
      <div className="ideabot-bot-message">
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default TypingIndicator;