import React, { useEffect, useState } from 'react'
import './styles.scss'
import avatar_b from "../../images/logo-tail.png"
import TextTypewriter from "../Typewriter"
import InitiativeBuble from '../initiativeBuble/index'


const ChatMessage = ({ index, isUser, text, newMessage, finishStatus, renderCount, isFirstMessage, bubbleOneClick }) => {

  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    if (newMessage.historyItem) {
      setRenderComponent(true);
    } else {
      const delay = 5800; // Delay in milliseconds (5.8 seconds)
      const timeoutId = setTimeout(() => {
        setRenderComponent(true);
      }, delay);
      return () => {
        clearTimeout(timeoutId); // Clean up the timeout if the component is unmounted before the delay is over
      };
    }
  }, []);

  return (
    <>
      <div className="ideabot-chat-message-wrapper">
        <div
          key={index}
          className={
            isUser
              ? "ideabot-user-message-row"
              : "ideabot-bot-message-row"
          }
        >
           {!isUser &&<div className="ideabot-avatar-wrapper">
             <img
              src={avatar_b}
              alt="avatar"
              className="ideabot-chat-message-avatar"
            />
          </div>}
          <div
            className={
              isUser
                ? "ideabot-user-message"
                : "ideabot-bot-message"
            }
          >
            <>
              {isUser || newMessage.historyItem ? (
                <p>{text}</p>
              ) : (
                <p>
                  <TextTypewriter text={text} finishStatus={finishStatus} countOfType={renderCount} />
                </p>

              )}

            </>
          </div>

        </div>
        {(isFirstMessage || index === 0) && renderComponent && (<div className="ideabot-initiative-message">
          <InitiativeBuble onClick={bubbleOneClick} text="What are your core services?" />
          <InitiativeBuble onClick={bubbleOneClick} text="I have a startup idea. Can you help me build my MVP?" />
          <InitiativeBuble onClick={bubbleOneClick} text="I’m looking to add an extended development team to my existing team. Can you help us with that?" />
        </div>)}


      </div>
    </>
  )
}

export default ChatMessage;