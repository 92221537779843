import React from 'react';
import Chatbot from './components/chat';
import { FocusProvider } from './context/focusContext'

const App = (props) => {
  return (
    <FocusProvider>
      <Chatbot apiKey={props.apiKey} />
    </FocusProvider>
  );
}

export default App;